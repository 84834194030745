import type { TaskProgress } from "~/api_gen/models/TaskProgress";
import type { BattlePassLevel } from "~/api_gen/models/BattlePassLevel";
import { useServerDateTime } from "~/composables/date/useServerDateTime";

type Event = {
  year: null | number;
  month: null | number;
  day: null | number;
  hours: null | number;
  minutes: null | number;
  seconds: null | number;
  tasks: {
    daily: TaskProgress[];
    weekly: TaskProgress[];
    constant: TaskProgress[];
  };
  tasksTimer: {
    general: {
      year: null | number;
      month: null | number;
      day: null | number;
      hours: null | number;
      minutes: null | number;
      seconds: null | number;
    };
    weekly: {
      year: null | number;
      month: null | number;
      day: null | number;
      hours: null | number;
      minutes: null | number;
      seconds: null | number;
    };
    daily: {
      year: null | number;
      month: null | number;
      day: null | number;
      hours: null | number;
      minutes: null | number;
      seconds: null | number;
    };
  };
  user: {
    currentLevel: null | number;
    defensePoints: null | number;
    levelCost: null | number;
  };
  levels: BattlePassLevel[];
};

export const useBullpassStore = defineStore("bullpassStore", () => {
  /**
   * Объект содержащий данные по текущему событию.
   */
  const event = ref<Event>({
    /**
     * Дата окончания ивента
     * @type {object}
     */
    year: null,
    month: null,
    day: null,
    hours: null,
    minutes: null,
    seconds: null,
    // Задания
    tasks: {
      /**
       * Массивы заданий
       */
      daily: [],
      weekly: [],
      constant: [],
    },
    // Таймеры заданий
    tasksTimer: {
      /**
       * Таймер общих задач отсутствует, но чтобы не ломать шаблон передаём Null
       */
      general: {
        year: null,
        month: null,
        day: null,
        hours: null,
        minutes: null,
        seconds: null,
      },
      /**
       * Таймер еженедельных заданий
       */
      weekly: {
        year: null,
        month: null,
        day: null,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      /**
       * Таймер ежедневных заданий
       */
      daily: {
        year: null,
        month: null,
        day: null,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    },
    // Данные пользователя
    user: {
      // Текущий уровень пользователя
      currentLevel: null,
      // Всего очков защиты
      defensePoints: null,
      // Очков защиты для след. уровня
      levelCost: null,
    },
    levels: [],
  });

  // Получение заданий
  async function getTasksInfo() {
    const api = useApi();

    try {
      const response = await api.quests.taskProgressList({
        slug: "winter_passs",
      });
      const dayArr = [];
      const weekArr = [];
      const constArr = [];

      for (const item of response) {
        if (item.type === "daily") {
          dayArr.push(item);
        } else if (item.type === "weekly") {
          weekArr.push(item);
        } else {
          constArr.push(item);
        }
      }

      // Записываем задания в таблицы
      event.value.tasks.daily = dayArr;
      event.value.tasks.weekly = weekArr;
      event.value.tasks.constant = constArr;
    } catch (error) {
      /* empty */
    }
  }

  // Получение объекта с информацией по ивенту в целом и пользователю в частном с авторизационными данными
  async function getEventInfoWithAuth() {
    const api = useApi();

    try {
      const response = await api.events.battlePassProgressRetrieve({
        slug: "winter_passs",
      });
      // Уровни Баттпасса
      event.value.levels = response.levels;
      // Получение и переформатирование даты окончания ивента
      const dataEnd = new Date(response.endDate).toLocaleString("en-US", {
        timeZone: "Europe/Moscow",
      });
      const dataEndMsc = new Date(dataEnd);

      event.value.year = dataEndMsc.getFullYear();
      event.value.month = dataEndMsc.getMonth();
      event.value.day = dataEndMsc.getDate();
      event.value.hours = dataEndMsc.getHours();
      event.value.minutes = dataEndMsc.getMinutes();
      event.value.seconds = dataEndMsc.getSeconds();

      if (response.currentLevel) {
        // Всего очков защиты
        event.value.user.defensePoints = response.currentLevel.doubloons;
        // Текущий уровень
        event.value.user.currentLevel = response.currentLevel.number;
        // Стоимость уровня
        event.value.user.levelCost = response.currentLevel.cost;
      } else {
        event.value.user.currentLevel = response.levels.length;
        event.value.user.levelCost = null;
        event.value.user.defensePoints = null;
      }
    } catch (error) {
      /* empty */
    }
  }

  // Устанавливаем время до перезапуска daiy и week заданий
  function setTablesTaskTimers() {
    const { serverDateTime } = useServerDateTime();

    // Для ежедневных добавляет 1, чтобы посчитать до конца текущего дня
    event.value.tasksTimer.daily.year = serverDateTime.getFullYear();
    event.value.tasksTimer.daily.month = serverDateTime.getMonth();
    event.value.tasksTimer.daily.day = serverDateTime.getDate() + 1;

    // Для еженедельных считаем сколько дней до конца недели осталось включая текущий
    const weekEnd =
      serverDateTime.getDay() === 0 ? 1 : 8 - serverDateTime.getDay();

    event.value.tasksTimer.weekly.year = serverDateTime.getFullYear();
    event.value.tasksTimer.weekly.month = serverDateTime.getMonth();
    event.value.tasksTimer.weekly.day = serverDateTime.getDate() + weekEnd;
  }

  // Устанавливает статус награды
  function setPrizeActivated(item: BattlePassLevel, activated: boolean = true) {
    if (item.userProgress) item.userProgress.isBonusActivated = activated;
  }

  return {
    event,
    getTasksInfo,
    getEventInfoWithAuth,
    setTablesTaskTimers,
    setPrizeActivated,
  };
});
